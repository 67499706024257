@px: 100rem;
.ant-form-item-has-error .ant-form-item-explain, .ant-form-item-has-error .ant-form-item-split {
  color: white;
  box-shadow: none;
}

.ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-input-affix-wrapper-focused, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background: transparent;
  border: none;
  box-shadow: none;
}


.ant-row {
  display: inline-block;
  background: transparent;
}

.ant-form-item {
  width: 400/@px;
  background: transparent;
  position: relative;
  margin-bottom: 0;
}

.ant-input-affix-wrapper {
  padding: 0;
  background: transparent!important;
  border: none!important;
}
/*.ant-input-password {
  input{
    text-indent: 55/@px!important;
  }
}*/

.ant-input-suffix {
  position: absolute;
  top: 7/@px;
  right: -50/@px;
  margin-top: 11/@px;
  width: 24/@px;
  height: 24/@px;

  svg {
    color: #e1e2e3;
    width: 24/@px;
    height: 24/@px;
  }
}