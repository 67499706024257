.reSetWidthHeight() {
  @px : 100rem;
  //html, body {
  //  //width: 720/@px;
  //  width: 100%;
  //  height: 100%;
  //  margin: 0 auto !important;
  //  font-size: 36px !important;
  //  background: white;
  //  overflow-x: hidden;
  //}

  * {
    font-size: 12/@px;
  }

  .content {
    height: 100%
  }

  .content > div {
    height: 100%
  }

  *::-webkit-scrollbar {
    display: none;
  }
  * {
    margin: 0;
    padding: 0;
    outline: none;
    font-weight: normal;
    box-sizing: border-box;
  }
}

.reSetWidthHeight();

