@px: 100rem;

.container {
  background: rgba(0, 0, 0, .6);
  //background: white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  margin: auto;
  z-index: 99;

  .loading_blue {
    text-align: center;
    vertical-align: middle;
    width: 133/@px;
    height: 133/@px;
    border-radius: 133/@px;
    line-height: 5/@px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: hidden;

    img {
      position: absolute;
      top: 41/@px;
      left: 0;
      right: 0;
      margin: auto;
      width: 87/@px;
      height: 52/@px;
      z-index: 9;
    }

    .loading_gray {
      text-align: center;
      vertical-align: middle;
      width: 133/@px;
      height: 133/@px;
      line-height: 5/@px;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 133/@px;
        height: 133/@px;
      }
    }
  }
}
