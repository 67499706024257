@px: 100rem;

.loading_ani{
  text-align: center;
  vertical-align: middle;
  width: 133/@px;
  height: 133/@px;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.18);
  overflow: hidden;
  animation-name: loading_ani;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes loading_ani {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
